<template>
  <b-row class="match-height">
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col cols="8">
            <h2 class="text-primary">Kas : {{ kas.nama }}</h2>
          </b-col>
          <b-col cols="8">
            <h3 class="text-info">Saldo : Rp. {{ kas.saldo ? formatRupiah(kas.saldo) : "-" }}</h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col>
  <b-card>
    <b-row>
      <b-col class="my-1">
        <b-button variant="primary" class="mr-1" @click.prevent="openFormModal">
         <feather-icon icon="PlusIcon"></feather-icon> Mutasi
        </b-button>
        <b-button variant="primary" @click.prevent="openFormModalJurnal">
         <feather-icon icon="PlusIcon"></feather-icon> Kas Debit
        </b-button>
        <form-modal @submit="submit"></form-modal>
        <form-jurnal @submitJurnal="submitJurnal"></form-jurnal>
        <!-- v-if="allowCreate() || allowUpdate()" -->
        <b-modal
          v-model="showModal"
          id="modal-"
          ok-title="Tutup"
          ok-variant="secondary"
          ok-only
          centered
          title="Form "
        >
          <validation-observer ref="formkas_alur">
            <div class="d-flex">
              <feather-icon icon="ArchiveIcon" size="19" />
              <h6 class="ml-50 mb-2">Data Kas Alur</h6>
            </div>
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    v-if="this.userData.level.id == 4"
                  >
                    <!-- label-for="v-id_karyawan" -->
                    <!-- label="Pilih Karyawan" -->
                    <validation-provider
                      #default="{ errors }"
                      name="id_karyawan"
                      :rules="{ required: true }"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.id_karyawan"
                        :options="id_karyawan"
                        id="v-id_karyawan"
                        name="id_karyawan"
                        disabled
                        hidden
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Pilih Karyawan"
                    label-for="v-id_karyawan"
                    v-if="this.userData.level.id == 7"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="id_karyawan"
                      :rules="{ required: true }"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.id_karyawan"
                        :options="id_karyawan"
                        id="v-id_karyawan"
                        name="id_karyawan"
                        disabled
                        hidden
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Pilih Jenis -->
                <b-col cols="12">
                  <b-form-group label="Pilih Jenis" label-for="v-jenis">
                    <validation-provider
                      #default="{ errors }"
                      name="jenis"
                      :rules="{ required: true }" >
                      <!-- <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.jenis"
                        :options="jenis"
                        id="v-jenis"
                        name="jenis"/> -->
                      <b-form-radio
                        v-model="form.jenis"
                        name="some-radio9"
                        value="1"
                        class="custom-control-info"
                      >
                      Masuk
                    </b-form-radio>
                      <b-form-radio
                        v-model="form.jenis"
                        name="some-radio9"
                        value="2"
                        class="custom-control-danger"
                      >
                        Keluar
                      </b-form-radio>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Tanggal -->
                <b-col cols="12">
                  <b-form-group label="Tanggal" label-for="v-tanggal">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="tanggal"
                    >
                      <flat-pickr
                        v-model="form.tanggal"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- nominal -->
                <b-col cols="12">
                  <b-form-group label="Nominal Kas Alur" label-for="v-nominal">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="kas_alur"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nominal"
                        id="v-nominal"
                        placeholder="Ex: 250.000"
                        @keyup="doFormatRupiah"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- keterangan -->
                <b-col cols="12">
                  <b-form-group label="Keterangan" label-for="v-keterangan">
                    <validation-provider
                      #default="{ errors }"
                      name="keterangan"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click.prevent="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    Add
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          
          <template #cell(tanggal)="{ item }">
            {{humanDate(item.tanggal)}}
          </template>
          <template #cell(debit)="{ item }">
            <strong
              >Rp
              {{
                item.debit > 0 ? formatRupiah(item.debit) : item.debit
              }}</strong
            >
          </template>
          <template #cell(kredit)="{ item }">
            <strong
              >Rp
              {{
                item.kredit > 0 ? formatRupiah(item.kredit) : item.kredit
              }}</strong
            >
          </template>
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(jenis)="data">
            <b-badge :variant="jns[1][data.value]">
              {{ jns[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <!-- <b-button
                                    v-if="allowUpdate() && item.modul == 'kas'"
                                    size="sm"
                                    @click.prevent="edit(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EditIcon" />
                                </b-button> -->
                                <b-button
                                    v-if="(allowDelete() && item.modul == 'kas')"
                                    size="sm"
                                    @click="removeJurnal(item)"
                                    class="mr-1"
                                    variant="outline-danger"
                                    >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
          <!-- <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only>
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
  </b-col>
</b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import FormModal from './components/FormModal.vue';
import FormJurnal from './components/FormJurnal.vue';
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  BCard,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
    BFormRadio,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    FormJurnal,
    FormModal,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_kas: 0,
        tanggal: new Date(),
        nominal: 0,
        keterangan: "-",
        id_karyawan: 0,
        jenis: 1,
      },
      jenis: [
        { value: 1, text: "Masuk" },
        { value: 2, text: "Keluar" },
      ],
      id_karyawan: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      kas: {},
      fields: [
        { key: "no", label: "no" },
        // { key: "id", label: "Id"},
        // { key: "karyawan.nama_lengkap", label: "Karyawan", sortable: true },
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "debit", label: "debit", sortable: true },
        { key: "kredit", label: "kredit", sortable: true },
        { key: "keterangan", label: "keterangan", sortable: true },
        { key: "akun.nama", label: "akun", sortable: true },
        { key: "actions", label: "Aksi", sortable: true },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      jns: [
        {
          1: "Masuk",
          2: "Keluar",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.id) {
      this.form.id_karyawan = userData.karyawan.id;
      this.form.id_kas = this.$route.params.id;
    };
    // this.form2.tanggal = this.getCurrentDate();
  },
  mounted() {
    this.getKas()
    this.getData();
    this.getCurrentKas();
  },
  methods: {
    openFormModal() {
      this.$bvModal.show('form-modal')
    },
    openFormModalJurnal() {
      this.$bvModal.show('form-jurnal')
    },
    doFormatRupiah() {
      this.form.nominal = this.formatRupiah(this.form.nominal)
      this.form2.debit = this.formatRupiah(this.form2.debit)
      this.form2.kredit = this.formatRupiah(this.form2.kredit)
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form2 = item;
      this.id = item.id;
      this.$bvModal.show('form-jurnal');
    },
    add() {
      this.resetForm();
      this.showModal = true;
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.id) {
        this.form.id_karyawan = userData.karyawan.id;
        this.form.id_kas = this.$route.params.id;
      }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data "${item.nama}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("kas_alur/save", [item])
            .then(() => {
              this.getData();
              this.pesanBerhasilHapus();
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    removeJurnal(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data transaksi pada tanggal : "${item.tanggal}" & keterangan : "${item.keterangan}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("kas/saveJurnalKas", [item])
            .then(() => {
        this.getData()
        this.getCurrentKas()
              // this.pesanBerhasilHapus();
              this.displaySuccess({
                            message: 'Data transaksi kas berhasil dihapus'
                        })
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        tanggal: new Date(),
        nominal: 0,
        keterangan: "",
        id_karyawan: 0,
        jenis: "",
      }
    },
    async submit(payload) {
      payload.dari_kas = parseInt(this.$route.params.id)

      try {
        this.$bvModal.hide('form-modal')
        this.loading = true
        // Do Ajax
        await this.$store.dispatch('kas/mutasiKas', [payload])
        this.loading = false
        this.displaySuccess({
          message: 'Uang dari kas berhasil dimutasikan'
        })
        this.getData()
        this.getCurrentKas()
      }
      catch(e) {
        this.$bvModal.show('form-modal')
        this.displayError(e)
        return false
      }
    },
    
    async submitJurnal(payload2) {
      payload2.id_kas = parseInt(this.$route.params.id)

      try {
        this.$bvModal.hide('form-jurnal')
        this.loading = true
        // Do Ajax
        await this.$store.dispatch('kas/saveJurnalKas', [payload2])
        this.loading = false
        this.displaySuccess({
          message: 'Transaksi dari kas berhasil ditambahkan'
        })
        this.getData()
        this.getCurrentKas()
      }
      catch(e) {
        this.$bvModal.show('form-jurnal')
        this.displayError(e)
        return false
      }
    },
    async getKas() {
      let cashes = await this.$store.dispatch('kas/getData')
      const findKas = cashes.find(kas => kas.id == this.$route.params.id)
      if(findKas) {
        this.$store.commit('kas/SET_CURRENT_KAS', findKas)
      }

      cashes = cashes.filter(kas => kas.id != this.$route.params.id)
      cashes = cashes.map(kas => ({value: kas.id, text: kas.nama, saldo: kas.saldo}))
      this.$store.commit('kas/SET_KAS_OPTION', cashes)
    },
    async getData() {
      //SEMUA kecuali SALES
      this.$store
        .dispatch("jurnal/getData", { order:"desc",id_kas: this.$route.params.id })
        .then(() => {
          this.items = this.$store.state.jurnal.datas;
          this.totalRows = this.items.length;
        });
    },
    async getCurrentKas() {
      const kas = await this.$store
        .dispatch("kas/getDataById", this.$route.params.id)
        this.kas = kas;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikas_aluri",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikas_aluri",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikas_aluri",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikas_aluri",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
  },
};
</script>
